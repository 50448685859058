import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import logo from "./pic1.jpg";

console.log(logo);

function App() {
  return (
    <div style={{}}>
      <header>Cyberscope I.K.E ΑΝΑΚΟΙΝΩΣΕΙΣ</header>
      <div>
        <image src={logo} alt="img" style={{ width: 100, height: 100 }} />
      </div>
    </div>
  );
}

export default App;
